import 'cookieconsent';
require('../scss/cookie.scss');

function startGoogleAnalytics() {
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-91030845-1', 'auto');
  ga('send', 'pageview');
}

window.cookieconsent.initialise({
  type: "opt-in",
  container: document.getElementById("content"),
  palette:{
    popup: {background: "#474949"},
    button: {background: "#446E9B"},
  },
  revokable:true,
  onInitialise: function(status) {
    console.log(this.hasConsented() ? 'enable cookies' : 'disable cookies');
    /*if (this.hasConsented()) {
      startGoogleAnalytics();
    }*/
  },
  onStatusChange: function(status) {
    console.log(this.hasConsented() ? 'enable cookies' : 'disable cookies');
    /*if (this.hasConsented()) {
      startGoogleAnalytics();
    }*/
  },
  law: {
    regionalLaw: false,
  },
  location: true,
  content: {
    "message": 'En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies permettant de réaliser des statistiques de fréquentation.',
    "link": '<a aria-label="learn more about cookies" tabindex="0" class="cc-link" target="_blank" href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs">En savoir plus</a>',
    "deny": "Refuser",
    "allow": "Accepter",
  }
});
